import { ElementType, HTMLAttributes } from 'react';

import { Box, Slider, SliderThumb, useTheme } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon';

import { sliderContainerStyles, sliderStyles, sliderThumbStyles } from './CustomSlider.styles';

export interface ICustomSliderProps {
	sliderValue: number;
	handleSliderChange: (event: Event, value: number | number[], activeThumb: number) => void;
}

export type ThumbComponentProps = HTMLAttributes<unknown>;

const ThumbComponent = (props: ThumbComponentProps) => {
	const theme = useTheme();
	const { children, ...other } = props;
	return (
		<SliderThumb data-test-id={'custom_slider_thumb'} {...other} style={sliderThumbStyles(props, theme)}>
			<TAGSvgIcon icon={'ChevronLeftFunc'} size={34} color="white" />
			{children}
			<TAGSvgIcon icon={'ChevronRightFunc'} size={34} color="white" />
		</SliderThumb>
	);
};

const CustomSlider = ({ sliderValue, handleSliderChange }: ICustomSliderProps) => {
	const theme = useTheme();
	return (
		<>
			<Box data-test-id={'before_after_slider'} sx={sliderContainerStyles(sliderValue, theme)} />
			<Slider
				value={sliderValue}
				onChange={handleSliderChange}
				components={{ Thumb: ThumbComponent as ElementType }}
				sx={sliderStyles}
			/>
		</>
	);
};

export default CustomSlider;
