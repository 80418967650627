import { Maybe } from '@/types';
import { ImageAsset } from '@/types/generated';

export const wrapper = {
	display: 'flex',
	width: '100%',
	alignItems: 'stretch',
	position: 'relative',
	marginBottom: 0,
	overflow: 'hidden',
};

export const paddingBoxSxOverride = {
	position: 'static',
};

export const videoStyles: React.CSSProperties = {
	width: '100%',
	height: '100%',
	minHeight: '23.5rem',
	objectFit: 'cover',
};

export const imageStyles = {
	display: 'block',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: 'auto',
	width: '100%',
	overflow: 'hidden',
	objectFit: 'cover',
};

export const imageAssetContainerWithSliderStyles = (useStaticImageSize: Maybe<boolean>) => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: 'auto',
	justifyContent: useStaticImageSize ? 'center' : 'flex-start',
});

export const imageAssetContainerStyles = (
	imageAssetTwo: Maybe<ImageAsset> | undefined,
	containerHeight: number | undefined,
	useStaticImageSize: Maybe<boolean>
) => {
	return {
		display: 'flex',
		width: '100%',
		flexGrow: useStaticImageSize ? 0 : 1,
		justifyContent: useStaticImageSize ? 'center' : 'flex-start',
		position: 'relative',
		...(imageAssetTwo && { height: containerHeight }),
	};
};

export const imageAssetContainerOneStyles = (
	imageAssetTwo: Maybe<ImageAsset> | undefined,
	sliderValue: number,
	useStaticImageSize: Maybe<boolean>
) => {
	return {
		flexBasis: !useStaticImageSize ? (imageAssetTwo ? `${sliderValue}%` : '100%') : 'auto',
		flexShrink: useStaticImageSize ? 1 : 0,
		overflow: 'hidden',
		...(imageAssetTwo && {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			clipPath: `inset(0 ${100 - sliderValue}% 0 0)`,
		}),
	};
};

export const imageAssetContainerTwoStyles = (sliderValue: number) => {
	return {
		flexBasis: `${100 - sliderValue}%`,
		flexShrink: 0,
		overflow: 'hidden',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		clipPath: `inset(0 0 0 ${sliderValue}%)`,
	};
};

export const beforeAfterBadgeStyles = (isBeforeImage: boolean) => ({
	position: 'absolute',
	...(isBeforeImage
		? {
				left: {
					xs: '1.43rem',
					md: '2%',
				},
		  }
		: {
				right: {
					xs: '1.43rem',
					md: '2%',
				},
		  }),
	top: {
		xs: '1.25rem',
		md: '2%',
	},
	zIndex: 9,
});
