import { Maybe } from '@/types';

export function resolveEyebrowTextColor({
	isDarkBackground,
	eyebrowStyle,
}: {
	isDarkBackground: boolean;
	eyebrowStyle?: Maybe<string>;
}) {
	return eyebrowStyle === 'Promo' ? 'success.dark' : isDarkBackground ? 'text.light' : 'text.secondary';
}
