import { Theme } from '@mui/material';

import { ThumbComponentProps } from './CustomSlider';

export const sliderContainerStyles = (sliderValue: number, theme: Theme) => {
	return {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: `${sliderValue}%`,
		width: '0.125rem',
		backgroundColor: theme.palette.primary.main,
		transform: 'translateX(-50%)',
	};
};

export const sliderThumbStyles = (props: ThumbComponentProps, theme: Theme) => {
	return {
		...props.style,
		width: '4.25rem',
		height: '4.25rem',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		border: `0.063rem solid ${theme.palette.text.light}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};
};

export const sliderStyles = {
	position: 'absolute',
	bottom: 50,
	left: '50%',
	transform: 'translateX(-50%)',
	width: '100%',
	'& .MuiSlider-track': {
		display: 'none',
	},
	'& .MuiSlider-rail': {
		opacity: 0,
	},
};
