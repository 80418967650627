import { useState } from 'react';

import { SxProps, Typography } from '@mui/material';

import { Maybe, TermsAndConditions as TTermsAndConditions, TermsAndConditionsContent } from '@/types/generated';
import RichText, { RichTextContent } from '@/components/RichText';

import TAGDialog from '../TAGDialog';
import TAGLink from '../TAGLink';
import TAGButton from '../TAGButton';

import { getTcTextStyles, dialogStyles } from './TermsAndConditions.styles';

export interface ITermsAndConditions extends TTermsAndConditions {
	/**
	 * Rich text content. If this is provided, the tcText will be rendered as a link.
	 *
	 * @type {Maybe<TermsAndConditionsContent>}
	 * @memberof ITermsAndConditions
	 */
	content?: Maybe<TermsAndConditionsContent>;
	/**
	 * The content of the dialog title.
	 *
	 * @type {Maybe<string>}
	 * @memberof ITermsAndConditions
	 */
	headlineText?: Maybe<string>;
	/**
	 * tcText will be rendered as anchor tag or typography based on the option.
	 * false = Link
	 * true = Text
	 * Default is false what represents Link and on click, popup will be displayed
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ITermsAndConditions
	 * @default false
	 * @deprecated
	 */
	showTcTextAs?: Maybe<boolean>;
	/**
	 * href link for a full page experience. If this is provided, the tcText will be rendered as a link.
	 *
	 * @type {Maybe<string>}
	 * @memberof ITermsAndConditions
	 */
	tcLink?: Maybe<string>;
	/**
	 * The text content of the component.
	 *
	 * @type {Maybe<string>}
	 * @memberof ITermsAndConditions
	 */
	tcText?: Maybe<string>;
	/**
	 * Indicates the color of the text. Useful when we have a dark background.
	 *
	 * @type {string}
	 * @memberof ITermsAndConditions
	 */
	textColor?: string;
	/**
	 * Optional styles for the tcText.
	 *
	 * @type {SxProps}
	 * @memberof ITermsAndConditions
	 */
	textSx?: SxProps;
}

export default function TermsAndConditions({
	content,
	tcLink,
	tcText,
	headlineText,
	textSx = {},
	textColor = 'text.secondary',
}: ITermsAndConditions) {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	if (!tcText) return null;
	const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
		if (content) {
			e.preventDefault();
			setIsDialogOpen((prev) => !prev);
		}
	};

	const handleClose = () => setIsDialogOpen(false);

	const shouldDisplayAsLink = tcLink || content;

	return (
		<>
			{shouldDisplayAsLink ? (
				<TAGLink href={tcLink || '#'} dataTestId="link_TAC" onClick={handleLinkClick}>
					<Typography
						variant="lowestLevelBook"
						sx={getTcTextStyles(textColor, { textDecoration: 'underline', ...textSx })}
					>
						{tcText}
					</Typography>
				</TAGLink>
			) : (
				<Typography variant="lowestLevelBook" sx={getTcTextStyles(textColor, textSx)} data-test-id="text_TAC">
					{tcText}
				</Typography>
			)}

			<TAGDialog
				open={isDialogOpen}
				dialogSx={dialogStyles}
				handleClose={handleClose}
				title={headlineText || 'Terms & Conditions'}
				renderTextContent={() =>
					content ? (
						<Typography variant="bodyMediumBook" data-test-id="text_content_TAC">
							<RichText
								content={content.json as RichTextContent}
								docProps={{ sx: { textAlign: 'center' } }}
								links={content?.links}
							/>
						</Typography>
					) : null
				}
				renderActions={() => (
					<TAGButton
						size="L"
						variant="secondaryDefault"
						dataTestId="button_close_TAC_dialog"
						onClick={handleClose}
					>
						Close
					</TAGButton>
				)}
			/>
		</>
	);
}
