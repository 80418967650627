// Return text as lowercase, replacing all special charaters with a dash
// Return a mnemonic error if input text is undefined
export const textToJumpLink = (text: string | undefined | null): string => {
	if (!text) {
		return 'undefinedJumpLink';
	}

	return text
		.toLowerCase()
		.replace(/[^a-zA-Z\d]/g, '-')
		.replace(/(-)\1+/g, '$1')
		.replace(/-$/, '')
		.replace(/^-/, '');
};

export const textToJumpLinkShorten = (text: string | undefined | null, length: number): string => {
	let label = text;
	if (text?.length && text?.length > length) {
		label = text.slice(0, length);
	}
	return textToJumpLink(label);
};
