import { SxProps } from '@mui/material';

export const getTcTextStyles = (color: string, sxProps: SxProps) => ({
	display: 'inline-block',
	color: color,
	...sxProps,
});

export const dialogStyles = {
	'.MuiPaper-root p': {
		padding: '0 10px',
	},
};
